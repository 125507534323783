import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import { BlogList } from '../components/templates/BlogList'

const Blogi = (props) => {
  return (
    <Layout
      metaTitle={'Blog | Pyry Palosaari'}
      metaDescription={'Lorem Ipsum Generator'}
      smallHeader
    >
      <BlogList {...props} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogIndex {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/blog/programming/" }
        frontmatter: { hide_post: { ne: true } }
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
            last_updated(formatString: "MMMM Do, YYYY")
            date(formatString: "MMMM Do, YYYY")
            author
            smallImage: image {
              childImageSharp {
                fluid(
                  maxWidth: 400
                  maxHeight: 225
                  cropFocus: CENTER
                  quality: 50
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            mediumImage: image {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 450, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            readingTime {
              text
            }
          }
          slug
        }
      }
    }
  }
`

export default Blogi
